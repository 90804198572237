import React from "react";
import { motion } from "framer-motion/dist/framer-motion";

export default function WhyChooseUsPage() {
	return (
		<div className="container">
			<div className="row align-items-center justify-content-between ">
				<div className="col-md-6 ">
					<div className="card border-white shadow-none">
						<div className="card-body ">
							<h4 className="card-title whoWeAreTitle">
								<b>WHY CHOOSE US</b>
							</h4>
							<br />
							<p className="card-text whoWeAreText">
								We understand the power of collaboration and how important it
								can be to the work we do. That is why we approach our strategic
								partnerships collaboratively, leveraging optimal synergies and
								working hard to provide the best and most seamless client
								experience possible. Make us your partner of choice for
								construction, energy, offshore and manufacturing projects.
							</p>
							<br />
							<br />
							<br />
						</div>
					</div>
				</div>

				<div className="col-md-6 ">
					<div className="card border-white shadow-none">
						<div className="card-body ">
							{/* <h6 className="card-title whoWeAreTitle">
									<b>WHY CHOOSE US</b>
								</h6> */}
							<br />

							<div className="text-block pt-3">
								<div className="g-gs">
									<div className="service service-inline service-s6">
										<div className="service-icon styled-icon styled-icon-4x styled-icon-s4 text-primary">
											{/* Image or icon required here */}
										</div>
										<div className="service-text">
											<motion.h5
												className="title fw-medium"
												whileHover={{
													scale: 1.2,
													transition: { duration: 1 },
												}}
												whileTap={{ scale: 0.9 }}>
                                        <i classname="fa-solid fa-ribbon fa-bounce"></i> {" "}
                                        &nbsp;
												Quality
											</motion.h5>
										</div>
									</div>
									<div className="service service-inline service-s6">
										<div className="service-icon styled-icon styled-icon-4x styled-icon-s4 text-primary">
											{/* Image or icon required here */}
										</div>
										<div className="service-text">
											<motion.h5
												className="title fw-medium"
												whileHover={{
													scale: 1.2,
													transition: { duration: 1 },
												}}
												whileTap={{ scale: 0.9 }}>
                                                    <i classname="fa-regular fa-lightbulb fa-beat"></i>
                                                    {" "}
                                                    &nbsp;
												Innovation
											</motion.h5>
										</div>
									</div>
									<div className="service service-inline service-s6">
										<div className="service-icon styled-icon styled-icon-4x styled-icon-s4 text-primary">
											{/* Image or icon required here */}
										</div>
										<div className="service-text">
											<motion.h5
												className="fw-medium"
												whileHover={{
													scale: 1.2,
													transition: { duration: 1 },
												}}
												whileTap={{ scale: 0.9 }}>
                                                    <i classname="fa-regular fa-face-laugh-beam fa-bounce"></i>
                                                    {" "}
                                                    &nbsp;
												Customer Satisfaction
											</motion.h5>
										</div>
									</div>
									<div className="service service-inline service-s6">
										<div className="service-icon styled-icon styled-icon-4x styled-icon-s4 text-primary">
											{/* Image or icon required here */}
										</div>
										<div className="service-text">
											<motion.h5
												className=" fw-medium"
												whileHover={{
													scale: 1.2,
													transition: { duration: 1 },
												}}
												whileTap={{ scale: 0.9 }}>
                                                    <i classname="fa-solid fa-people-carry-box fa-fade"></i>
                                                    {" "}
                                                    &nbsp;
												Community Responsibility
											</motion.h5>
										</div>
									</div>
									<div className="service service-inline service-s6">
										<div className="service-icon styled-icon styled-icon-4x styled-icon-s4 text-primary">
											{/* Image or icon required here */}
										</div>
										<div className="service-text">
											<motion.h5
												className=" fw-medium"
												whileHover={{
													scale: 1.2,
													transition: { duration: 1 },
												}}
												whileTap={{ scale: 0.9 }}>
                                                    <i classname="fa-solid fa-people-line fa-flip"></i> {" "}
                                                    &nbsp;
												Social Oriented
											</motion.h5>
										</div>
									</div>
								</div>
								<h6 className="mt-5">
									<b>
										Make us your partner of choice for construction, energy,
										offshore and manufacturing projects.
									</b>
								</h6>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
