import React from "react";
import NavBar from "../components/navBar/NavBar";
import Footer from "../components/footer/footerComponent";
import { Accordion } from "react-bootstrap"; // Ensure correct import

export default function OurServicesPage() {
	const servicesData = [
		{
		  title: "Diesel Engines",
		  icon: "fa-gears fa-bounce",
		  description: [
			"Sales",
			"Installation",
			"Troubleshooting",
			"Repair",
			"Maintenance",
			"Repair/Overhaul of Turbocharger",
			"Repair of Governor/Actuator",
			"Repair of Fuel Injectors",
		  ],
		  brands: "We offer the above for Different Brands of engines such as: MAK, Wartisla, Duetz, Detroit, Perkins, NIIGATA, Cummins, CAT, etc.",
		},
		{
		  title: "Industrial Engines & Generators",
		  icon: "fa-industry fa-bounce",
		  description: ["Repair", "Maintenance", "Sales", "Spare Parts"],
		  brands: "Cummins, Mikano, JMP, Lion Rock, etc.",
		},
		{
		  title: "Inboard & Outboard Engines",
		  icon: "fa-gear",
		  description: ["Sales", "Accessories", "Installation", "Repair and Services"],
		  brands: "Yamaha, Suzuki, Mercury, OXE, etc.",
		},
		{
		  title: "HVAC",
		  icon: "fa-snowflake fa-bounce",
		  description: [
			"Sales",
			"Service",
			"Installation",
			"Spare Parts",
			"Refrigerants: R404, 134A, 401C",
			"Compressor Oils",
		  ],
		  brands:
			"Above for Compressor, Condenser-Indoor, Outdoor, Evaporators and Cooling units: Copeland, Carrier, National, LG, Bitzer, Danfoss, Harp, Atlas Copco, Sauer, etc.",
		},
		{
		  title: "Electrical & Electronics",
		  icon: "fa-plug-circle-bolt fa-bounce",
		  description: [
			"Sales",
			"Installation",
			"Repair",
			"Automation",
			"Process Control",
		  ],
		  brands:
			"Cables: Sales & Installation, Electrical Motors: AC & DC: ABB, Siemens, Weg, General Electric, Nidec, Teco, Faulhaber, Hoyer, Hitachi. Rewind and Repair of Alternators: Leroy Somer, Stamford, Marelli, MECC Alte, Marathon, etc.",
		},
		{
		  title: "Solar Systems",
		  icon: "fa-solar-panel fa-bounce",
		  description: [
			"Solar Inverters",
			"Solar Batteries",
			"Solar Panels",
			"Charge Controllers",
			"Starter & Backup Battery",
			"UPS",
			"Sales",
			"Installation",
			"Maintenance",
		  ],
		  brands: "Solar Inverters, Solar Batteries, Solar Panels, & Charge Controllers, Starter & Backup Battery, UPS"
		},
		{
		  title: "PLCs & Power Supply",
		  icon: "fa-lightbulb fa-bounce",
		  description: [
			"Sales & Installation",
			"Control Panel",
			"Installation",
			"Troubleshooting",
			"Design",
			"Repair",
		  ],
		},
		{
		  title: "Pumps",
		  icon: "fa-gas-pump fa-bounce",
		  description: ["Sales", "Installation", "Repair"],
		  brands: "Gear, Flow, Centrifugal, Rotary, Hydraulic, Submersible, Booster, etc.",
		},
		{
		  title: "Welding",
		  icon: "fa-gears fa-bounce",
		  description: [
			"Gas Welding",
			"Pipe Welding",
			"Steel Welding",
			"Cast Welding",
			"Fabrication",
			"Schematic design and construction",
		  ],
		},
		{
		  title: "Lubricants",
		  icon: "fa-gas-pump fa-bounce",
		  description: [
			"Mineral & Synthetic Lubricants",
			"DPK, AGO, PMS, ATK",
		  ],
		  brands: "Gear Oil: Mobil, Shell, Total",
		},
		{
		  title: "Construction & Leasing",
		  icon: "fa-screwdriver-wrench fa-bounce",
		  description: [
			"Barges: Dumb, boat haulage, power barge, split hopper",
			"Lifting Equipment: Crane, Hoist, Platforms, Winch, Ropes, Sling, Wire, Forklift",
			"Trucks: Tipping truck, Sided-body, Flat bed, Crane truck, Tanker, Box truck",
			"Gear, Flow, Centrifugal, Rotary, Hydraulic, submersible, Booster etc"
		  ],
		},
		{
		  title: "Navigation & Communication",
		  icon: "fa-compass fa-bounce",
		  description: ["Sales", "Troubleshooting", "Installation", "Programming"],
		  brands: "Radar, GMDSS, EPIRB, etc. (Sailor, Jotron, NAVTEX, Furuno, Samyung)",
		},
		{
		  title: "Safety System",
		  icon: "fa-shield fa-bounce",
		  description: [
			"Sales",
			"Installation",
			"Programming",
			"Addressable & Conventional Fire Alarm",
			"Industrial Safety Tools",
			"Safety Equipment and Gadgets",
			"Safety Protection Solutions",
		  ],
		},
		{
		  title: "Procurement",
		  icon: "fa-credit-card fa-bounce",
		  description: [
			"All Brands of Engine Spare Parts",
			"Engineering Equipment",
			"Outsource",
			"Shipment (Air & Sea)",
			"Freight Forwarding",
			"Warehouse and Terminal Storage",
		  ],
		},
	  ];
	  
  return (

    <div className="services-page">
      <NavBar />
      <div className="container py-4 ">
        <div className="text-center">
          <h1 className="page-title fw-bold">Our Services</h1>
          <p className="lead">
          Backed by years of experience, we bring this strong mooring services expertise to bear for our clients, 
		  who include many multinational and indigenous oil and gas companies for whom safety and reliability are paramount.
          </p>
          <hr className="my-4 mx-auto" style={{ width: "60px" }} />
        </div>
        <div className="row mt-4">
          <div className="col-lg-12">
            <Accordion defaultActiveKey="0" flush>
              {servicesData.map((service, index) => (
                <Accordion.Item eventKey={index.toString()} key={index} className="my-3">
                  <Accordion.Header>
                    <i className={`fa-solid ${service.icon} me-3`}></i>
                    <strong>{service.title}</strong>
                  </Accordion.Header>
                  <Accordion.Body className="services-background">
                    <ul>
                      {service.description.map((item, idx) => (
                        <li className="services-font" key={idx}>{item}</li>
                      ))}
                    </ul>
                    {service.brands && (
                      <p>
                        <strong>Brands: </strong>
                        <span><b>{service.brands}</b></span>
                      </p>
                    )}
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
